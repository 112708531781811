body {
    min-height: 100%;

    .container-xlg-layout & {
        background: $body-bg 50% 0 image-url('bg2.png') no-repeat;
    }
    &.no-bg {
        background-image: none !important;
    }
    &.woman-bg {
        background: $body-bg 50% 15px image-url('bg-woman2.png') no-repeat;
    }
}

.inline-editable a:hover {
    cursor: pointer !important;
}

.input-date-picker {
    border: none;
    height: 24px;
    padding-top: 0;
    padding-bottom: 0;
    &:focus {
        border: none;
    }
}

/**
CHAT FUEL
 */
.chat_block {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 15% 40%;
}

.main-container {
    width: 100%;
    padding-left: 0;
    padding-right: 15px;
}

#wrapper {

}

.main-content {
    padding: 38px;
    margin-bottom: 30px;
    border-radius: 6px;
    background-color: #fff;
    min-height: 600px;
}

.content-tabs {
    margin-bottom: 30px;
    border-radius: 6px;
    background-color: #fff;

    &.compact {
        margin-bottom: 0;
    }
}
.boxed-shadow {
    box-shadow: $block-shadow;
}

.primary-light-footer {
    background-color: #ebfaf4;
}

.url-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.block-success {
    width: 480px;
    margin: 0 auto 35px;
    border-radius: 6px;
}
.bsc-container {
    padding: 15px;
    margin-top: -6px;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-radius: 6px;
    text-align: center;
    box-shadow: $block-shadow;

    img {
        display: none;
    }
}

.toggle-item {

    &.active {

        .toggle-rounded {
            background-image: svg-url('times.svg');
        }
        .toggle-container {
            padding-top: 25px;
        }
    }
    small {
        margin-right: 4px;
        font-weight: 700;
    }
}
.toggle-row {
    margin-bottom: 0;
}

.toggle-collapse {
    margin-top: 10px;
    transition: all linear .2s;
}
.toggle-container {
    padding-top: 0;
    transition: padding linear .1s;
}


.toggle-rounded {
    display: inline-block;
    float: right;
    @include square(40px);
    margin-left: 8px;
    border-radius: 50%;
    text-align: center;
    background: #f6f7f7 50% 50% svg-url('times.svg') no-repeat;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

    &:hover {
        background-color: darken(#f6f7f7,5%);
    }
}

.well {
    border: 0;
    box-shadow: none;

    &.well-s {
        font-size: 14px;
    }
}

.note-validated-state {
  color: #000;
  margin-bottom: 10px;

}

.tooltip-inner {
    min-width: 500px !important;
}

.main-content-col {
  counter-reset: displayStepNumber;

  .form-section h3 {
    display: inline;
  }

  .count {
    &::after {
      counter-increment: displayStepNumber;
      content: " " counter(displayStepNumber);
    }
  }
}


/*
    Plugin lightbox
 */
.ekko-lightbox-container {
    height: 730px !important;
}

div[id^='ekkoLightbox'] > div , div[id*=' ekkoLightbox'] > div{
    max-width: 600px !important;
}

/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-tablet) {


}
@media (min-width: $screen-tablet) and (max-width: $screen-desktop - 1) {

}
@media (min-width: $screen-lg-desktop) {

    .container-xlg-layout {

        .main-container {
            background: 266px -152px image-url('bg2.png') no-repeat;
        }
        body {
            background-image: none;
        }
    }
}

@media (min-width: $screen-xlg-desktop) {

    .container-xlg-layout {

        .main-container {
            background-position: 270px -30px;
        }
    }
    .hidden-xlg {
        display: none !important;
    }
}

@media (max-width: $screen-xlg-desktop - 1) {

    .visible-xlg {
        display: block !important;
    }
}
@media (max-width: $screen-lg-desktop - 1) {

}
@media (max-width: $screen-desktop - 1) {

}
@media (max-width: $screen-tablet - 1) {
    .condition-service-text {
       >div {
           margin-top: 0 !important;
       }
    }
}
@media (max-width: 526px) {

    .block-success {
        width: 100%;
    }
    .success-content {

        > div img {
            //display: none;
            max-height: 140px;
        }
    }
    .bsc-container {

       img {
           @include img-responsive(inline-block);
       }
    }
}
@media (max-width: $screen-phone - 1) {

}
