/*
   Functions
   ========================================================================== */

@function image-url($string) {
    @return url('#{$img-path}#{$string}');
}
@function svg-url($string) {
    @return url('#{$svg-path}#{$string}');
}

/*
   Placeholders
   ========================================================================== */

%font-thin {
    font-weight: 100;
}
%font-light {
    font-weight: 300;
}
%font-regular {
    font-weight: 400;
}
%font-medium {
    font-weight: 500;
}
%font-semi {
    font-weight: 600;
}
%font-bold {
    font-weight: 700;
}
%font-black {
    font-weight: 900;
}

%italic {
    font-style: italic;
}

/*
   Mixins
   ========================================================================== */

//== Fonts
@mixin font($font-placeholder, $font-family, $font-weight, $font-style:false) {

    $style: if($font-style, str-slice($font-style, 1, 1), '');
    $weight: if($font-weight != 'regular', str-slice(#{$font-weight}, 1, 1), '');
    $weight-style: if($style != '' or $weight != '', '-', '');
    $pattern: #{$weight-style}#{$weight}#{$style};

    //@warn (#{$font-placeholder}#{$pattern});

    .#{$font-placeholder}#{$pattern} {
        @extend %#{$font-placeholder} !optional;
        @extend %font-#{$font-weight} !optional;

        @if ($font-style == italic) {
            @extend %italic !optional;
        }
    }
}

//== Block helpers
@mixin loop-block-spaces($property, $integer, $direction:false) {

    $p: str-slice($property, 1, 1);
    $d: if($direction != false, str-slice($direction, 1, 1), '');
    $full-patern: #{$p}#{$d};
    $class: if($direction != false, $full-patern, $p);
    $the-property: if($direction != false, $property + '-'+ $direction, $property);

    .#{$class}-0 {
        #{$the-property}: 0 !important;
    }

    @while $integer > 0 {

        .#{$class}-#{$integer} {
            #{$the-property}: #{$integer}px !important;
        }
        $integer: if($integer == 0, 0, $integer - 5);
    }
}

//== Outline buttons
@mixin button-outline-variant($bg-color, $color: false, $hover-color:false, $border-color: false) {
    color: if($color != false, $color, $bg-color);
    background-color: $button-outline-variant-bg-color;
    border-color: if($border-color != false, $border-color, $bg-color);
    $outline-hover-color: if($hover-color != false, $hover-color, $btn-color);

    &:hover {
        color: $outline-hover-color;
        background-color: $bg-color;
        border-color: $bg-color;
    }

    &:focus,
    &.focus {
        color: $outline-hover-color;
        background-color: $bg-color;
        border-color: $bg-color;
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $outline-hover-color;
        background-color: $bg-color;
        border-color: $bg-color;

        &:hover,
        &:focus,
        &.focus {
            color: $outline-hover-color;
            background-color: darken($bg-color, 17%);
            border-color: darken($bg-color, 25%);
        }
    }

    &.disabled,
    &:disabled {

        &:focus,
        &.focus,
        &:hover {
            background-color: $button-outline-variant-bg-color;
            border-color: lighten($bg-color, 20%);
            color: $bg-color;
        }
    }
}


//== Widget titles colors
@mixin widget-colors($title-bg, $title-color, $content-bg) {

    .widget-title {
        background-color: $title-bg;
        color: $title-color;

        .media-body {
            overflow: visible;
        }

        i {
            color: $title-color;
        }
    }
    .widget-content {
        background-color: $content-bg;
    }
}
