//== Select 2
$select2-color: $input-color;
$select2-border-color: $input-border;
$select2-color-font-size: $font-size-base;

$select2-selection-single-height-base: $input-height-base;
$select2-selection-single-bg: $input-bg;
$select2-selection-single-border-radius: 6px;

$select2-selection-arrow-height-base: $input-height-base;
$select2-selection-arrow-icon-width: 14px;
$select2-selection-arrow-icon-height: 20px;
$select2-selection-arrow-icon-font-size: 20px;

$select2-selection-multiple-min-height: $input-height-base;
$select2-selection-multiple-border-radius: 6px;
$select2-selection-multiple-border-color: $input-border;

$select2-selection-choice-bg: $brand-primary;
$select2-selection-choice-border-color: $select2-selection-choice-bg;
$select2-selection-choice-border-radius: 0;
$select2-selection-choice-color: #fff;
$select2-selection-choice-remove-color: #fff;

$select2-highlighted-bg: #e7e9ee;
$select2-highlighted-text-color: $input-color;
$select2-selected-bg: $brand-primary;
$select2-selected-text-color: #fff;

$select2-border-size-focus: 1px;
$select2-border-color-focus: $input-border-focus;
$select2-border-color-focus-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($input-border-focus, 10%);

$select2-xs-inline-min-width: 80px;

$select2-success-color: #339999;
$select2-danger-color: #ed1b24;
