.step-nav {
    display: table;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 6px;

    span.step {
        display: table-cell;
        //width: percentage(1/5);
        padding: 12px 10px 12px 25px;
        font-weight: 700;
        text-align: center;
        text-decoration: none !important;

        background-color: #fff;
        border: none;
        border-radius:0;
        position: relative;
        z-index: 2;
        &:before {
            content: '';
            width: 50%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 28px;
            left: 0;
            z-index: 1;
        }
        &:after {
            content: '';
            width: 50%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 28px;
            right: 0;
            z-index: 1;
        }

    }
    span.step:last-child {
        &:after {
            display: none;
        }
    }
    span {
        @include square(32px);
        background-color: #f6f6f6;
        text-align: center;
        line-height: 28px;
        color: $text-color;
        border-radius: 16px;
        display: block;
        margin: 0 auto;
        padding:3px 0;
        position:relative;
        z-index: 2;
    }
    .active {
        color: $brand-dark;
        &:before, &:after {
            background: #3bd89b !important;
        }
        span {
            background-color: #d3fbf1;
            border-color: $brand-primary;
            color: $brand-primary;
        }
    }
    .validated {
        color: $brand-dark;
        &:after {
            background: #3bd89b !important;
        }
        &:before {
            background: #3bd89b !important;
        }
        span {
            background-color: #d3fbf1;
            border-color: $brand-primary;
            color: $brand-primary;
        }
    }
}

@media (min-width: 613px) {

    .step-nav {
        background-color: #fff;
        box-shadow: $block-shadow;

        span.step {

            &:first-child {
                background-image: none;
            }
        }
        svg {
            display: none;
        }
    }
}

@media (max-width: 612px) {

    .step-nav {

        &, span.step {
            display: block;
            width: 100%;
            text-align: left;
            background-position: 100% 50%;
        }
        span.step {
            margin-bottom: 4px;
            padding-left: 10px;
            background: 96% 50% svg-url('angle-right-hight.svg') no-repeat;
            background-color: #fff;
            box-shadow: $block-shadow;
            border-radius: 6px;
        }
    }
}
