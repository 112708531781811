.menu-switcher {
    display: inline-block;

    .dropdown-menu {
        overflow-y: auto;
        max-height: 400px;
    }

    .dropdown-menu>li>a {
        background: transparent !important;
        width: 100%;
        margin: 0;
        line-height: 40px;
    }
    .dropdown-menu>li>a:hover {
        background: grey !important;
        width: 100% !important;
        border-radius: inherit !important;
        color: white;
    }
    .dropdown-toggle {
        background: transparent;
    }
    .dropdown-toggle .caret {
        margin-left: 10px;
    }
}
