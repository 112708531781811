
@media (min-width: $screen-desktop) and (max-width: $screen-xlg-desktop - 1) {

    .certif-item {

        p.small {
            min-height: 60px;
        }

        div.folder-message {
            height: auto;
        }
    }
}

.certif-item {

    div.folder-message {
        height: auto;
        font-size: 87%;
    }
}
