$inline-color: if(is-dark($body-bg), #FFFFFF, $brand-dark);

.ff-inline-border {
    padding: 20px;
    border-radius: 10px;
}

.ff-inline-bg, .ff-bg {
    background-color: lighten($brand-primary, 50%);
}

.ff-inline-school-bg, .ff-school-bg {
    background-color: lighten($body-bg, 40%) !important;
    color: $brand-dark !important;

    &.ff-time {
        color: $body-bg !important;
        background-color: inherit !important;
        border: 1px solid;
    }
    &.btn {
        color: $body-bg !important;
        border: 1px solid;
        &:hover {
            background-color: $body-bg !important;
            color: lighten($body-bg, 40%) !important;
        }
    }
}

.dz-complete {
   img, canvas {
        transition: transform 0.3s;
        &:hover {
            -ms-transform: scale(4); /* IE 9 */
            -webkit-transform: scale(4); /* Safari 3-8 */
            transform: scale(4);
            z-index: 12;
            position: relative;
        }
   }
   canvas {
        max-height: 100px;
   }
}

.ff-step-title, .ff-info-1-common {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ff-step-inline-title * {
        margin-bottom: 0 !important;
    }

    .ff-step1-info {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ff-border {
    padding: 10px;
    border-radius: 5px;
}

.ff-external-link {
    min-width: 200px;
    margin-left: 20px;
}

.tickbox-editable p, .tickbox-editable-add p {
    margin-bottom: 5px !important;
}

.tickbox-editable-add {
    margin: 0 20px 0 20px;
}

.ff-inline-school-bg, .ff-school-bg {
    background-color: lighten($body-bg, 40%) !important;
    color: $brand-dark !important;

    &.ff-inline-school-bg {
        color: $inline-color !important;
    }

    &.ff-time {
        color: $body-bg !important;
        background-color: inherit !important;
        border: 1px solid;
    }
    &.btn {
        color: $body-bg !important;
        &:hover {
            background-color: $body-bg !important;
            color: lighten($body-bg, 40%) !important;
        }
    }
}

.ff-step-title, .ff-info-1-common {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .btn-outline-ff {
        border: $body-bg 1px solid;
        color:  inherit;
        background-color: inherit !important;
        
        &:hover {
            background-color: $body-bg !important;
            color:#FFFFFF !important;
        }
    }

    .ff-step1-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .paper-guide-and {
            position: relative;
            display: inline-block;
            &:before, &:after {
                content: "";
                position: absolute;
                top: 50%;
                width: 100%; 
                height: 1px;
                background-color: #758388;
            }
            &:before {
                left: 30px;
                width: 60px;
            }
            &:after {
                right: 30px;
                width: 60px;
            }
        }
    }

    .ff-step-title-content {
        display: flex;
        align-items: center;
        h1, h2, h3 {
            margin-bottom: 0 !important;
        }

        .clock-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 50px;
            height: 40px;

            svg {
                fill: $body-bg;
            }

            .text-inside {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 8px; 
                font-weight: bold;
                color: $body-bg;
            }

        }
    }
}

@media (max-width: 576px) { /* col-sm breakpoint */
    .ff-step-title, .ff-info-1-common {
        flex-direction: column;
    }
}