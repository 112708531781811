.content-nav {
    margin: 25px 0;
    margin-left: -10px;

    &.compact {
        margin-top: 0;
    }

    &, li, a {
        border: 0 !important;
    }

    a {
        background-color: #fff !important;
    }
    span {
        display: inline-block;
        position: relative;
    }

    &.cn-small {

        a {
            font-size: 14px;
        }
    }

    .active {

        a {
            font-weight: 700;

            span {

                &:after {
                    content: '';
                    @include size(100%,2px);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -6px;
                    background-color: $brand-dark;
                }
            }
        }
    }
}
