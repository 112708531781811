.status-item-new {
    margin-bottom: 25px;
    border: 1px solid $brand-gray;
    border-radius: 6px;

    > .media {
        display: table;
        min-height: 78px;
        padding: 15px 15px;
    }
    .media-left,
    .media-body {
        vertical-align: middle;
        line-height: 1;
    }
    .media-left {

        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .media-body {
        line-height: 1;
        vertical-align: middle;

        .media {
            margin-top: 0;
        }
    }
    .svg-creditcard,
    .svg-metro-stroke {
        stroke: $brand-secondary;
    }
    .svg-metro-fill {
        fill: $brand-secondary;
    }
    .svg-electricity,
    .svg-world,
    .svg-money,
    .svg-health {
        stroke: $brand-red;
    }

    &:hover{
        border: 1px solid $brand-primary;

    }
}

.sin-title {
    margin-bottom: 4px;
    padding-left: 2px;
    line-height: 1;
}
.sin-name {
    font-weight: 700;
    color: $brand-dark;
}
.sin-status {
    float: right;
    margin-left: 4px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 8px;
    text-transform: uppercase;

    .invalid & {
        color: $brand-red;
    }
    .pending & {
        color: $text-color;
    }
    .completed & {
        color: $brand-primary;
    }
}
.sin-date {
    font-size: 14px;
    font-weight: 400;
    color: $text-color;
}

.sin-progress {
    padding-right: 6px;

    + .media-body {
        padding-top: 10px;
        vertical-align: bottom;
    }
}



@media (max-width: $screen-phone) {


}
