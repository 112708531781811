#accordion .panel-faq .panel-title a {
    background-color: white;
    display: block;
    padding: 30px;
    color: $brand-primary;
    font-size: larger;
    font-weight: bold;
}

.block-question {
    display: block;
    background-color: white;
    margin-bottom: 30px;
    padding: 30px;
    border: solid 1px black;

    .bi-title {
        color: $brand-primary;
        font-size: 21px;
    }

}
