.documents-container {
    .documents-menu {
        padding-right: 32px;
        .list-group-item {
            cursor: pointer;
            border: none;
            border-radius: 8px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .check {
                width: 16px;
                margin-left: 10px;
            }

            &.active {
                font-weight: 700;
            }

            &:not(.active) {
                background-color: transparent;
            }
        }
    }

    .documents-upload-container {
        padding-left: 32px;

        .documents-upload-area {
            .uploader {
                margin-top: 20px;
            }
        }
    }
}
