.uploader-zone-content {
    .upload-in-progress {

      position: relative;
      display: table;
      @include size(100%,113px);
      padding: 7px;
      background-color: #fff;
      user-select: none;

      .fu-cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        label {

        }
      }
    }
}