.alert {
    border: 0;
    padding: 25px 15px;

    &.alert-danger {

        background-color: #fdf0f2;

        &, a {
            color: #e4425a;
        }

        .media-left {
            padding-top: 3px;
        }
    }
    &.alert-error {
        background-color: #e4425a;

        &, a {
            color: #fff;
        }
    }

    &.folder-message {
        box-shadow: 0 10px 60px 10px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background-color: #e4425a;
        color: white !important;
        margin-bottom: 5px !important;
        // height: 88px;
        display: flex;
        align-items: center;
        padding-left: 50px;
        font-weight: bold;
        font-size: 16px;

        >i {
            margin-right: 20px;
        }
    }
}

@media (max-width: $screen-phone) {
    .alert {
        &.folder-message {
        box-shadow: 0 10px 60px 10px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background-color: #e4425a;
        color: white !important;
        margin-bottom: 5px !important;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
        font-size: 10px;

        >i {
            margin-right: 10px;
        }
    }
    }

}
