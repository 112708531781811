.life-item {
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: $block-shadow;

    a {
        color: $brand-primary;
        font-weight: 700;
    }
svg
{
    color: $brand-primary;

}
}
