.community-block {


    .main-content {
        position: relative;
        padding: 0;
    }

    .community-img-wr {
        margin: 10px 0 0;
    }
    .cb-header, .cb-inline {
        padding: 25px;
    }
    .primary-light-footer {
        padding: 25px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        span {
            color: $text-color;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .form-control-wr {
        padding: 10px 0 4px;

        .fc-label {
            padding: 0 15px;
        }
        .select2-container .select2-selection--single,
        .select2-container--default .select2-selection--single .select2-selection__arrow {
            height: 30px;
            border-radius: 0;
            border: 0;
            padding-left: 7px;
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {
            line-height: 34px;
        }
    }
}
.csearch-form {

    .btn {
        padding: 21px 20px 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid darken($brand-primary,5%);

        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.community-align {
    display: flex;
    .community-align-block {
        flex: 1;
    }
}
.csearch-f-result {

    .fcw-label {
        padding: 10px;

        h3 {
            margin-bottom: 0;
        }
    }
}

.csearch-f-result-label-title {
    display: none;
}
.csearch-f-result {

    .cb-header {
        padding-bottom: 0;
        min-height: 600px;
    }
    .pagination {
        margin-top: 0;
    }
}

.c-table {

    th {
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
    }
    > thead {

        > tr {

            > th {
                border-bottom: 0;
            }
        }
    }
    > tbody {

        > tr {

            > td {
                padding-top: 16px;
                padding-bottom: 16px;
                font-size: 14px;

                &:first-child {
                    font-weight: 700;
                    color: $brand-dark;
                }
            }
            &:first-child {

                > td {
                    border-top: 0;
                }
            }
        }
    }
}







@media (min-width: $screen-desktop) {

    .community-block {

        .cimg {
            height: 170px;
            margin: 0 0 -36px 46px;
            overflow: hidden;
        }
    }
    .fcw1,
    .fcw2 {

        .form-control-wr {
            border-right: 0;

        }
    }
    .fcw1 {

        .form-control-wr {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
    }
}
@media (min-width: $screen-desktop) and (max-width: $screen-lg-desktop - 1) {

    .community-block {

        .cimg {
            height: auto;
            margin: 0 0 -36px 0;
        }
    }
}
@media (min-width: $screen-xlg-desktop) {

    .fcw-col {
        width: 20%;
    }
    .fcw-btn {
        width: 6.2%;
    }
}

@media (max-width: $screen-xlg-desktop - 1) {

    .csearch-f-result-label-title {
        display: block;
    }
    .csearch-f-result {

        .fcw-label {
            display: none;
        }
    }
}
@media (max-width: $screen-lg-desktop - 1) {

    .primary-light-footer {

        span {
            display: block;
        }
    }
}
@media (max-width: $screen-desktop - 1) {

    .community-block {

        .community-img-wr {
            padding: 15px;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            + .main-content {
                margin-top: -8px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    .fcw1,
    .fcw2 {

        .form-control-wr {
            border-bottom: 0;
        }
    }
    .csearch-form {

        .btn {
            width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            text-align: right;
        }
    }
}

