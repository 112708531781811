// @import url('https://fonts.googleapis.com/css?family=Catamaran:300,400,600,700');

@font-face {
    font-family: 'Catamaran';
    src: url('../../../public/fonts/Catamaran-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Catamaran';
    src: url('../../../public/fonts/Catamaran-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Catamaran';
    src: url('../../../public/fonts/Catamaran-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Catamaran';
    src: url('../../../public/fonts/Catamaran-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/*
   Poppins
   ========================================================================== */

%catamaran {
    font-family: $font-family-catamaran;
}
.catamaran-l {
    @extend %catamaran;
    font-weight: 300;
}
.catamaran {
    @extend %catamaran;
    font-weight: 400;
}
.catamaran-sb {
    @extend %catamaran;
    font-weight: 600;
}
.catamaran-b {
    @extend %catamaran;
    font-weight: 700;
}

.catamaran-i,
.catamaran-li,
.catamaran-sbi,
.catamaran-bi {
    @extend .catamaran;
    @extend %italic;
}
