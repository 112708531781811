.text-white {
    color: #fff;
}
.text-color {
    color: $text-color;
}
.text-heading {
    color: $brand-dark;
}
.text-gray {
    color: $brand-gray;
}
.text-secondary {
    color: $brand-secondary;
}

.text-black {
    color: $brand-black;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

    &:first-child {
        margin-top: 0;
    }
}
h1, h2, h3,
.h1, .h2, .h3 {
    margin-bottom: 25px;
}
p {
    margin-bottom: 20px;
}
.alert {

    h4 {
        color: $headings-color;
    }
}

.welcome-msg {
    margin-bottom: 46px;
    font-size: 20px;
}

.onboarding-page {
    background:#fff;
    .main-container {
        padding:0;
    }
    .row {
        display:flex;
        flex-wrap:wrap;
    }
    .onboarding-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .inner-div {
        max-width:560px;
        padding-right:80px;
        padding-top:30px;
        padding-bottom:30px;
        text-align: center;

        @media(max-width: 991px) {
            max-width:100%;
            padding:50px 80px;
        }
        @media(max-width: 575px) {
            padding:50px 40px;
        }

    }
    .img-part {
        display:flex;
        justify-content:center;
        align-items:center;
        background:#f1fdfa;

        @media(max-width: 991px) {
            padding:100px;
        }
        @media(max-width: 575px) {
            padding:50px;
        }
    }
}

.onboarding-video-block .embed-responsive-16by9 {
    border-radius: 8px;
}

.help-block {
    color: $text-color;
    font-size: 14px;
}

.wysiwyg-content {

    ul, ol {
        padding-left: 18px;
        margin-bottom: 20px;
    }
    a {
        color: $brand-primary;
    }
}

.check-list {
    list-style-type: none;

    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        color: $brand-dark;

        &:before {
            content: "\f00c";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $brand-primary;
            position: absolute;
            left: 0;
            top: 3px;
            font-size: 12px;
        }
    }
}

#img_serial_number {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}


@media (max-width: $screen-tablet - 1) {

    h1, .h1 {
        font-size: 28px;
    }
    h2, .h2 {
        font-size: 24px;
    }
    h3, .h3 {
        font-size: 20px;
    }
    h4, .h4 {
        font-size: 18px;
    }
    h5, .h5 {
        font-size: 16px;
    }
}
