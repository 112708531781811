svg {

    &, use {
        transition: all ease .2s;
    }
}

.svg-calendar {
    color: $brand-dark;
}

.svg-bell,
.svg-question {
    stroke: $brand-dark;
}
.svg-drop {
    stroke: $label-color;
}

.svg-aside-nav {
    stroke: $text-color;
}

.svgs-bg {
   stroke: #d3d8d9;
}

.svgs-progress {
    stroke: $brand-dark;
}

.svg-creditcard,
.svg-metro-stroke,
.svg-electricity,
.svg-world,
.svg-money,
.svg-health {
    stroke: $brand-primary;
}
.svg-metro-fill {
    fill: $brand-primary;
}

.svg-globe {
    fill: $brand-secondary;
}

.svg-trash {
    stroke: $text-color;
}

.svg-question-circle {
    fill: $text-color;
}


.svg-arrow-right{
    fill: #fff;
    stroke: #fff;
}

.svg-arrow-left {
    stroke: $brand-dark;
    transition: all ease .4s;
    &-white {
        stroke: #FFFFFF;
        transition: all ease .4s;
    }
}
