.contact-upgrade-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;

  .inline-editing {
    outline: 1px dotted $white;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
  }

  .collapse-toggle {
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $brand-dark;
    border-radius: 8px;

    .fa {
      transition: transform 200ms ease-in-out;
    }

    &.open {
      .fa {
        transform: rotate(180deg);
      }
    }
  }

  .status-item {
    cursor: pointer;
    border: 1px solid $brand-gray;
    border-radius: 6px;
    height: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-width: 345px;

    @media (max-width: $screen-phone) {
      max-width: 100%;
    }

    &.disabled {
      background-color: $brand-dark-gray;

      .si-name {
        color: $white;
      }

      .si-date {
        color: $white;
      }

      .media-left {
        padding: 0 15px;
        align-self: baseline;

        svg {
          display: inline-block;
          vertical-align: middle;

          g {
            fill: $white;
          }

          path {
            fill: $white;
          }

          use {
            stroke: $white;
          }
        }
      }

      .media-body {
        width: 100%;
        padding-right: 10px;
      }

      .svg-creditcard,
      .svg-metro-stroke {
        stroke: $white;
      }

      .svg-metro-fill {
        fill: $white;
      }
    }

    .media {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      flex: 1;
    }

    .media-left,
    .media-body {
      vertical-align: middle;
      line-height: 1;
    }

    .media-left {
      padding: 0 15px;

      svg {
        display: inline-block;
        vertical-align: middle;

        use {
          stroke: $brand-black;
        }
      }
    }

    .media-body {
      width: 100%;
      padding-right: 10px;
    }
  }
}