#footer {
    padding: 46px 0;
    background-color: #282b2c;

    &, a {
        color: #fff;
        font-weight: 300;
    }
}

.ft-title {
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
}
.ft-logo {
    margin-bottom: 25px;

    span {
        display: block;
        margin: 20px 0 35px;
    }
}
.ft-social {

    li {
        display: inline-block;
        margin-right: 8px;
    }
    a {
        display: inline-block;
        position: relative;
        @include square(46px);
        background-color: #3a3a3a;
        border-radius: 50%;
        text-align: center;
        transition: all ease .5s;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: 18px;
            @include square(46px);
            line-height: 46px;
        }

        &:hover {
            background-color: $brand-primary;
        }
    }
}

.ft-nav {
    margin-bottom: 35px;

    li {
        margin-bottom: 25px;
        line-height: 1;

        a {
            color: #cfcfd0 !important;
        }
    }
}


@media (min-width: $screen-desktop) {


}

@media (max-width: $screen-tablet - 1) {

    #footer {
        padding: 20px 0;
    }
    .ft-nav {
        margin-bottom: 15px;

        li {
            margin-bottom: 4px;
        }
    }
    .ft-title {
        margin-bottom: 8px;
    }
    .ft-social {
        margin-bottom: 0;

        a, a i {
            @include square(32px);
        }
        a i {
            line-height: 32px;
        }
    }
    .ft-logo {
        margin-bottom: 15px;

        img {
            width: 120px;
        }
        span {
            //display: inline-block;
            margin: 5px 0 10px;
            font-size: 11px;
        }
    }
}
@media (max-width: $screen-phone - 1) {


    .ft-navs-container {

        > .row {

            > div {
                width: 100%;
                float: none;
            }
        }
    }
}
