.c-stepper {
  display: flex;
  flex-wrap: wrap;
  --size: 3rem;
  --spacing: 0.5rem;
  margin: 0 0 0 0;
  padding: 0 5px 0 5px;

  &.ff-service-stepper {

    @media (min-width: 768px) {
      margin-left: -100px;
      margin-right: -100px;
    }

  }
}
.c-stepper__item {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:not(:last-child):after {
    content: "";
    position: relative;
    top: 35px;
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
    width: calc(100% - var(--size) - calc(var(--spacing) * 2));
    left: calc(50% + calc(var(--size) / 2 + var(--spacing)));
  }

  &:last-child:after {
    content: "";
    position: relative;
    top: 35px;
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
    width: 50px;
    left: calc(50% + calc(var(--size) / 2 + var(--spacing)));
  }

  &:first-child:before {
    content: "";
    position: relative;
    top: 37px;
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
    width: 50px;
    left: calc(50% + calc(var(--size) / 2 + var(--spacing)) - 90px);
  }

  .c-stepper__title {
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: inherit;
    }

    & .c-stepper__number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid #7D939B;
      color: $white;
      border-radius: 50%;
      font-weight: bold;
      background-color: $body-bg;
      border-color: $body-bg;

      &.active {
        border-color: if($body-bg == #128790, $white, #128790);
        color: if($body-bg == #128790, $white, #128790);
      }

      // &.success {
      //   color: $white;
      //   background-color: $brand-primary;
      //   border-color: $brand-primary;
      // }

      // &.warning {
      //   color: $white;
      //   background-color: $orange;
      //   border-color: $orange;
      // }

      // &.danger {
      //   color: $white;
      //   background-color: $brand-red;
      //   border-color: $brand-red;
      // }

      & + .tooltip {
        & > .tooltip-inner {
          background-color: $brand-dark;
          color: #FFFFFF;
          border: 1px solid $brand-dark;
          padding: 15px;
          font-size: 16px;
          min-width: 300px !important;
        }
      }
    }

  }

  .c-stepper__desc {
    margin: 22px 0 0;
    user-select: none;
    font-size: 14px;

    &.active, &.current {
      font-weight: bold;
      color: $brand-black;
    }
  }
}

@media only screen and (max-width: 767px) {
  /* Styles for screens narrower than 768px (mobile devices) */
  .c-stepper__item:last-child:after,
  .c-stepper__item:first-child:before {
    display: none;
  }
}

.lifeoncampus-stepper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    
    .c-stepper {
        width: 100%;
    }
}