.avatar-aside {
    padding: 25px 46px;
    //background-color: #e2faf1;
    //background-color: #10069F;
    text-align: center;

    .as-wr {
        @include size(137px,121px);
        padding-top: 15px;
        margin: 0 auto 15px;
    }

    img {
        vertical-align: bottom;
        height:121px;
        width:137px;
        object-fit: contain;
    }

    strong {
        display: block;
        font-size: 20px;
        color: $headings-color;
    }
}

.user-meta {
    padding: 15px 10px;
}
.um-info {

    strong {
        font-size: 16px;
        color: $headings-color;
    }
    a {
        float: right;
    }
}
.um-date {
    margin: 15px 0;
}

.um-item {
    display: block;
    padding: 8px;
    color: $brand-secondary;
    background-color: #f2fafd;
    border-radius: 6px;

    strong {
        font-size: 32px;
        line-height: 1;
    }
    span {
        display: block;
        font-size: 16px;
        font-weight: 300;
    }
}


.aside-nav {
    padding-left: 15px;
    padding-right: 15px;

    .active {

        a {
            background-color: #f6f7f7;
            color: $brand-dark !important;
            font-weight: 700;
        }
        .svg-aside-nav {
            stroke: $brand-dark !important;
        }
    }

    a {
        display: block;
        padding: 10px;
        text-decoration: none !important;
        border-radius: 6px;

        &:hover {

            .svg-aside-nav {
                stroke: $brand-primary;
            }
        }
    }
    li {
        margin-bottom: 15px;
        line-height: 1;

        .media-body {
            vertical-align: middle;
        }
        .media-left {

            svg {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}


@media (max-width: $screen-desktop - 1) {

    .avatar-aside {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}