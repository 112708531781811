#information_block {
  .panel-body a {
    color: $brand-primary;
    text-decoration: underline;
  }
}

#modal-qa {
  .qa-title {
    display: flex;
    font-family: $font-family-catamaran;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #172E36;

    p:after {
      content: "";
      display: block;
      border-bottom: 4px solid #128790;
      max-width: 21px;
    }
  }

  .modal-body {
    padding: 30px;

    .contact-must-read {
      padding: 27px 9px 13px 17px;

      .contact-panel {
        padding: 21px;
        color: #172E36;

        p {
          text-align: center;

          .phone-number, .mail {
            color: #128790;
          }
        }

        .block-1 {
          margin-bottom: 6px;
        }
      }

      .must-read {
        padding: 21px;
        color: #172E36;

        .title {
          margin-left: 20px;
        }

        .bullet {

          &.first {
            padding-left: 0;
          }

          li {
            list-style-type: none;
            position: relative;
          }

          li:before {
            content: ".";
            margin-top: -1.5rem;
            margin-left: -8px;
            font-size: 29px;
            position: absolute;
          }
        }
      }
    }

    .must-read-close {
      padding-right: 25px;
      padding-top: 10px;
    }
  }

  .btn-collapse {
    color: #172E36;

    &.btn-collapse {
      width: 42px;
      height: 42px;
      margin: 13px;
    }

    &:focus {
      background-color: #ffffff;
      color: #172e36;
      border-color: transparent;
    }

    &:not(.collapsed) {
      svg {
        transform: rotate(180deg);
      }
    }

    &.collapsed {
      svg {
        transform: rotate(0deg);
      }
    }
  }


  .btn-contact-must-read {
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    justify-content: center;

    &:focus {
      background-color: #ffffff;
      color: #172e36;
      border-color: transparent;
    }

    &:hover {
      color: #172e36;
    }
  }
}

.qa-head {
  display: flex;

  .question {
    color: #172E36;
    margin: 20px 104px 20px 30px;
    width: 80%;
  }
}

.panel-answer {
  p {
    margin: 1px 94px 20px 27px;
  }
}

.polygon {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background-color: #ffffff;
  height: 50px;
  width: 25px;
  position: absolute;
  margin-top: -26px;
  &.must-read{
    margin-left: 72%;
  }
  &.contact{
    margin-left: 20%;
  }
}
