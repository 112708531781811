.pagination {


    li {
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;

        a {
            padding: 0;
            @include square(32px);
            line-height: 32px;
            border-radius: 50% !important;
            transition: all ease .5s;
        }
    }
}