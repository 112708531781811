/*
   Main header
   ========================================================================== */

.main-header {

    .navbar-default {

        .navbar-toggle {
            width: 34px;
            padding-left: 0;
            padding-right: 0;
            margin-right: 25px;
            float: left;
        }
        .icon-bar {
            @include size(100%,3px);

            + .icon-bar {
                margin-top: 6px;
            }
        }
    }

    .school-logo {
        img {
            max-height: 50px;
            padding-right: 30px;
        }
    }
}

.header-school-logo {
    float: left;
    padding: 10px 46px;
    img {
        height: 80px;
        padding-top: 15px;
        margin: 0 auto 15px;
    }
}

.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
    display: none;
    @media (min-width: $grid-float-breakpoint) {
        display: none !important;
    }
}

.navbar-margin {
    @media (min-width: $screen-md-min) {
        margin-left: 270px;
    }
}



/*
   Onboarding header
   ========================================================================== */

.user-area {
    display: inline-block;
    float: right;

    a {
        display: inline-block;
        @include square(40px);
        margin-left: 8px;
        background-color: #f6f7f7;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;

        &:hover {

            .svg-bell,
            .svg-question,
            .svg-logout {
                stroke: $brand-primary;
            }
        }
    }


}

.profile-picture {
    display: inline-block;
    vertical-align: top;

    a {
        width: auto;
        margin: 0;
        padding-bottom: 11px;
        height: auto;
        border-radius: 0;
        background: none;
    }

    img {
        width: 51px;
        height: 51px;
        object-fit: cover;
        border-radius: 8px;
    }

    .profile-name {
        color:  #172e36;
    }
}

/*
   Header
   ========================================================================== */


.user-notification {
   position: relative;

   span {
       display: block;
       @include square(14px);
       position: absolute;
       top: -4px;
       right: 0;
       background-color: #f63864;
       color: #fff;
       text-align: center;
       line-height: 14px;
       font-size: 12px;
       border-radius: 50%;
   }
}
.user-question {

}
.user-avatar {

   img {
       @include square(40px);
       border-radius: 50%;
   }
}
.user-logout {

   &:hover {

       .svg-logout {
           fill: $brand-primary;
       }
   }
}

.navbar-header {
    padding: 15px 15px 15px 15px;
    float: none;
}

.main-header {
    box-shadow: 0 10px 60px 10px rgba(0, 0, 0, 0.05);
}

.push-col {
    flex: 0 0 270px;
}
#header {
    padding: 15px 0 7px;
    min-height: 96px;
    margin-bottom: 40px;
    background-color: transparent;
    display: flex;

    .main-nav {
        flex: 1;
    }
    .header-wrapper  {
        width: 83% !important;
    }

    &.onboarding-header {
        min-height: unset;
        margin-bottom: 0px;
        .locale-switcher {
            float: right;
            margin-right: -15px;
            .dropdown-menu {
                text-align: center;
            }
        }
        .btn {
            float: right;
            text-align: left;
            line-height: 1.8;
            font-size: 18px;
        }
        .logo {
            margin-top: 10px;
        }
    }

    .h2 {
        margin: 0;

        a {
            font-size: 16px;
            font-weight: 400;
        }
        .media-body {
            width: auto;
        }
    }

    .navbar-default {
        margin-bottom: 0;
    }
}

.header-page-svg {
    padding-right: 15px;
    vertical-align: middle;

    svg {
        display: inline-block;
    }
}
.header-single-page-title {
    display: inline-block;
}
.header-breadcrumb {
    display: none;

    > a {
        display: block;
        text-decoration: none !important;

        &:hover {

            .svg-arrow-left {
                stroke: $brand-primary;
            }
            .breadcrumb-page-title {
                color: $brand-primary;
            }
        }
    }
    .media-body {
        width: auto;
    }
}
.h-breadcrumb {
    display: inline-block;

    a {
        position: relative;
        display: inline-block;
        padding-right: 20px;
        margin-right: 10px;
        text-decoration: none !important;
        background: svg-url('angle-right-hight.svg') 100% 2px no-repeat;
        color: $brand-primary;
    }
    span {
        color: $brand-dark;
    }
}
.breadcrumb-page-title {
    transition: all ease .4s;
}

.m-breadcrumb {
    padding-top: 15px;

    > a {
        display: block;
        text-decoration: none !important;

        &:hover {

            .breadcrumb-page-title {
                color: $brand-primary;
            }
        }
    }
    .breadcrumb-page-title {
        margin: 0 0 3px;
    }

    .media-left {
        display: none;
    }
}



/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-tablet) {


}
@media (min-width: $grid-float-breakpoint) {

    .header-breadcrumb {
        display: inline-block;
    }
    .m-breadcrumb {
        display: none;
    }
}
@media (min-width: $screen-tablet) and (max-width: $screen-desktop - 1) {


}
@media (min-width: $screen-desktop) {


}
@media (min-width: $screen-lg-desktop) {


}

@media (max-width: 991px) {
    #header {
        display: block;
    }
    .container {
        width: auto !important
    }

}

@media (max-width: $grid-float-breakpoint-max) {

    #header {
        padding: 0;
        margin-bottom: 0;
        min-height: auto;
    }
    .navbar-header {
        padding-bottom: 0;
    }
    .navbar-toggle {
        margin-top: 17px;
    }
}
@media (max-width: $screen-desktop - 1) {

}
@media (max-width: $screen-tablet - 1) {


}
@media (max-width: $screen-phone) {


}
