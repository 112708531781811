.welcome-pack-delivery {
  .radio {
    border: 1px solid $text-color;
    border-radius: $border-radius-base;
    padding: 10px;

    label {
      font-weight: bold;
      font-size: $font-size-h4;
      color: $brand-black;
    }
  }

}