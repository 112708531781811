.static-body {
  background-color: $tertiary-blue;
  min-height: 100%;
  padding-top: 40px;

  .static-translate {
    text-align: center;
  }

  h1 {
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  .container {
    margin-top: 80px;
    padding-bottom: 120px;
  }
}