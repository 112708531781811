.mce-content-body  a, div.modal-body a, a.text-link {
    color: $brand-primary !important;
}


//green link in inline editable content for students execpt tabs
.main-content a:not(.tab-navigation, .btn),
.visa-success-block a:not(.has-btn-icon-left, .has-btn-icon-right, .vi-btn),
{
    color: $brand-primary !important;
}

.mce-content-body.checklist-item ul {
    list-style: none;
    padding-left: 0;
    @extend .check-list;
}
