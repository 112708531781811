.owl-carousel {
    z-index: auto;

    .owl-next,
    .owl-prev {
        outline: 0 !important;

        &.disabled {
            display: none;
        }
    }
}

.owl-theme .owl-dots {
    text-align: left;
    padding-left: 8px;
}
.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #fff;

}
.owl-dot {
    outline: 0 !important
}

.owl-theme .owl-nav {
    //display: none;

    .disabled {

        &:hover {
            opacity: .3;
        }
    }
    [class*=owl-]:hover {
        background: transparent;
        color: $brand-primary;
    }
}


.icon {
    color: rgba(255, 255, 255, .9);
    font-family: 'FontAwesome';
    line-height: 60px;
    margin-top: -30px;
    position: absolute; top: 50%;
    text-align: center;
    width: 20px;
    height: 20px;
}
.owl-next,
.owl-prev {
    height: 100%;
    position: absolute;
    top: 0;
    width: 33.33333333%;
}
.owl-next {
    right: 0;
}
.owl-prev {
    left: 0;
}
.owl-next:hover .icon,
.owl-prev:hover .icon {
    color: rgba(255, 255, 255, .6);

}
.icon-arrow-left7 { left: 0; }
.icon-arrow-left7:before { content: "\f053"; }
.icon-arrow-right7 { right: 0; }
.icon-arrow-right7:before { content: "\f054"; }

.large-icon {
    font-size: 25px;
}
