.modal-content {
    border: 0;
    box-shadow: $block-shadow;
}

/*
   Modal postulate
   ========================================================================== */

#modal-success {

    .modal-dialog {
        width: 480px;
    }
    .modal-content {
        background-color: transparent;
        box-shadow: none;
    }
}

/*
   Aside panel
   ========================================================================== */

.aside-panel {
    top: 0;
    padding-right: 0 !important;

    &.modal.fade:not(.in).right .modal-dialog {
        -webkit-transform: translate3d(125%, 0, 0);
        transform: translate3d(125%, 0, 0);
    }
    .modal-dialog {
        position: fixed;
        right: -1px;
        top: 0;
        margin: auto;
        @include size(101%,100%);
        box-shadow: -3px 0 8px -4px rgba(0,0,0,0.5);
    }
    .modal-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .modal-body {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        padding: 25px;
        overflow: auto;
    }
}

.lateral-close {
    @include clearfix();

    .lc-close {
        display: block;
        float: right;

        &:hover {
            opacity: .7;
        }
    }
}

.ap-actions {

    a {
        display: block;
        padding: 10px;
        margin-top: 15px;
        background-color: #effbf7;
        color: $brand-primary;
        font-weight: 700;
        font-size: 16px;
        border-radius: 6px;
        text-decoration: none !important;
        transition: all ease .5s;

        svg {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
        }

        .media {
            display: table;
        }

        .media-left {
            min-width: 35px;
        }
        .media-left,
        .media-body {
            vertical-align: middle;
        }
        .media-body {
            line-height: 1.7;
        }

        &:hover,
        &:focus {
            background-color: darken(#effbf7,5%);
        }
    }
}

.help-list {

    li {
        border-top: 1px solid #e8eaeb;

        &:first-child {
            border: 0;
        }

        a {
            display: block;
            padding: 14px 8px;
            text-decoration: none !important;
        }
    }
}

@media (min-width: 500px) {


    #modal-help {

        .modal-dialog {
            width: 350px;
        }
    }
}
@media (min-width: $screen-phone) and (max-width: $screen-tablet) {

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 10px;
    }
}
@media (min-width: $screen-tablet) {

}
@media (min-width: $screen-desktop) {


}

@media (max-width: $screen-desktop - 1) {


}
@media (max-width: $screen-tablet - 1) {


}

@media (max-width: 526px) {

    .bsc-container {

       img {
           @include img-responsive(inline-block);
       }
    }
    #modal-success {

        .modal-dialog {
            width: auto;
        }
    }
}
@media (max-width: $screen-phone - 1) {


}

/**
=============== Side modal =====================
 */
.side-modal{
    right: 0;
    padding-right: 0!important;
    .modal-dialog{
        top: 0;
        margin-top: 0!important;
        margin-right: 0;
    }

    .modal-content{
        background-color: #F2FAFD;
    }
}